import React, { useState } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { MdOutlineArrowDropDown } from "react-icons/md"
import './Value.css';
import data from '../../utils/accordion'
const Value = () => {
 // const [classNames, setClassNames] = useState({});
 const [classNames, setClassNames] = useState(null);
  return (
    <div>
      <section className='v-wrapper'>
        <div className='paddings innerWidth flexCenter v-container'>
          {/* left side */}
          <div className='v-left'>
            <div className='image-container'>
              <img src='./Value.png' />
            </div>
          </div>


          {/* right side */}
          <div className='flexColStart v-right'>
            <span className='orangeText'>Our Value</span>
            <span className='primaryText'>Value We Give to You</span>
            <span className='secondaryText'>We always ready to help by providing the best service for you.
              <br />
              We believe a good to live can make your life better.
            </span>
            {/* {console.log(className)} */}
            <Accordion className='accordion' allowMultipleExpanded={false}

              preExpanded={[0]}>
              {
                
                data.map((item, i) => {
               //const [classNames, setClassNames] = useState(null);
                // const changeState = (expand)=>{

                //   console.log("pok", expand,i);
                //     let str = expand ? 'expand': 'collapse'
                //     setClassName({})
                  
                //   }
                  return (
                    <AccordionItem className={`accordionItem ${classNames}`} key={i} uuid={i}>
                      <AccordionItemHeading>
                        <AccordionItemButton className='flexCenter accordionButton'>

                          <AccordionItemState >
                          
                            {/* {({ expanded }) =>changeState(expanded)} */}
                            {({expanded})=> expanded ? setClassNames("expanded") : setClassNames("collapsed")}
                            {/* {({ expanded }) =>
                      setClassNames((prevClassNames) => ({
                        ...prevClassNames,
                        [i]: expanded ? 'expanded' : 'collapsed',
                      }))
                    } */}
                   
                  
                          </AccordionItemState>

                          <div className='flexCenter icon'>{item.icon}</div>
                          <span className='primaryText'>
                            {item.heading}
                          </span>
                          <div className='flaxCenter icon'>
                            <MdOutlineArrowDropDown size={20} />
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>

                      <AccordionItemPanel>
                        <p className='secondaryText'>{item.detail}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  )
                })
              }
            </Accordion>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Value;
