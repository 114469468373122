import React from 'react';
import "./Hero.css";
import { HiLocationMarker } from 'react-icons/hi';
import CountUp from 'react-countup'
import {motion} from 'framer-motion'
const Hero = () => {
    return (
        <section className='hero-wrapper'>
            <div className='paddings innerWidth flexCenter hero-container'>

                <div className='flexColStart hero-left'>
                    {/* left section */}

                    <div className='hero-title'>
                        <div className='orenge-circle' />
                       <motion.h1
                    //    initial={{y: "2rem", opacity:0}}
                    //    animate={{y: "2rem", opacity:1}}
                    //    transition={{
                    //         duration: 2,
                    //         type:"spring"
                    //    }}
                >
                       <h1>Discover <br />Most Suitable<br /> Property</h1>
                       </motion.h1>
                    </div>
                    <div className='flexColStart hero-des'>
                        <span className='secondaryText'>Find a variety of properties that suit you very easily</span>
                        <span className='secondaryText'>Forget all diffeculties in finding a rasidence for you</span>
                    </div>
                    <div className='flexCenter search-bar'>
                        <HiLocationMarker color="var(--blue)" size={25} />
                        <input type='text' />
                        <button className='button'>Search</button>
                    </div>
                    <div className='flexCenter stats'>
                    <div className='flexColCenter stat'>
                        <span>
                            <CountUp start={8800} end={9800} duration={4}/>
                            <span>+</span>     
                        </span>
                        <span className='secondaryText'>Premium Products</span>

                    </div>
                    <div className='flexColCenter stat'>
                        <span>
                            <CountUp start={1900} end={2500} duration={4}/>
                            <span>+</span>
                        </span>
                        <span className='secondaryText'>Happy Customers</span>

                    </div>
                    <div className='flexColCenter stat'>
                        <span>
                            <CountUp end={28}/>
                            <span>+</span>        
                        </span>
                        <span className='secondaryText'>Award Wining</span>

                    </div>
                    </div>
                </div>
                <div className='flexCenter hero-right'>
                    {/* right side */}
                    {/* <motion.div
                       initial={{x: "7rem", opacity:0}}
                       animate={{x: "7rem", opacity:1}}
                       transition={{
                            duration: 2,
                            type:"spring"
                       }}
                       >  */}
                    <div className='image-container'>
                        <img src='./hero-image.png' alt='' />
                    </div>
                    {/* </motion.div> */}

                </div>
            </div>
        </section>
    )
}

export default Hero
