import React from 'react'
import './GetStarted.css'
const GetStarted = () => {
    return (
        <section className='g-wrapper'>
            <div className='paddings innerWidth g-container'>
                <div className='flexColCenter inner-container'>
                    <span className='primaryText'>Get Started With Homzy</span>
                    <span className='secondaryText'>Subscribe and find super attractive price quotes
                        <br />
                        Find your Residenc soon
                    </span>
                    <button className='button'>
<a href='mailto:sahilkhanskkhan4@gmail.com'>Get Started</a>                    </button>
                </div>
            </div>
        </section>
    )
}

export default GetStarted
